import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CareersDetailsView from '../../components/CareersDetails';

const CareersDetails = ({ location, data }) => (
  <CareersDetailsView
    location={location}
    title="Technical Project Manager - Software Careers | MCRO"
    description={`This will be a long term opportunity with a competitive compensation package. In this role you will 
    be accountable for planning and delivering large-scale, medium to complex strategic initiatives often involving a 
    matrix of constituents as well as external delivery partners through all phases of the SDLC (Waterfall and agile).`}
    metaContent="project manager, google cloud services, amazon web services, elastic search, cloud formation,
    deployments"
    id="project-manager"
    jobTitle="Technical Project Manager"
    jobDescription="This will be a long term opportunity with a competitive compensation
     package. In this role you will be accountable for planning and delivering large-scale,
     medium to complex strategic initiatives often involving a matrix of constituents as
     well as external delivery partners through all phases of the SDLC (Waterfall and agile)."
    mailHref="mailto:jobs@mcro.tech?subject=DevOps Job Application"
    responsibilities={[
      'Lead and contribute to all phases of System Design Life Cycle (SDLC) - project initiation, project planning,'
      + ' requirements management, technical design and development, test planning and execution of highly complex'
      + ' and visible Information Technology projects',
      'Define and track project milestones while developing, maintaining, and reporting on an overall integrated'
      + ' delivery plan that includes contingency plans, inter- and intra-project and solution dependencies,'
      + ' and other related project management artifacts',
      'Adherence to scope management and change control processes; assess and evaluate scope changes for feasibility'
      + ' and impacts on commitments',
      'Negotiates and manages scope to ensure commitments are achieved within agreed upon time, cost, and quality'
      + ' parameters',
      'Estimates and tracks project financials; effective at resource and capacity planning',
      'Experienced at identifying risks, issues, and mitigation plans and working with leadership to drive to'
      + ' resolution',
      'Develops and tracks project performance metrics; tracks and communicates out on benefit realization'
    ]}
    requirements={[
      '3 to 6 years of software delivery project management experience',
      'Good understanding of multiple SDLC methodologies including Waterfall and Agile',
      'Experienced or advanced knowledge of multiple IT disciplines including software designs and architecture,'
      + ' business process engineering, requirement gathering, development and unit testing, quality assurance'
      + ' methods, etc.',
      'Prior experience with project delivery of large integrated projects, business process management or'
      + ' commercial software, large data conversion projects, and/or legacy replacement projects',
      'Experience of managing projects across multiple countries on a global basis',
      'Cultural competency, organizational skills, negotiation skills and customer focus',
      'Advanced skills using project scheduling tools'
    ]}
    communicationSkills={[
      'Must be able to communicate with business/non-technical groups',
      'Must be able to rapidly build semi-working prototypes',
      'Must have the ability to work in a team',
      'Must be able to help communicate ideas and best practices to more junior developers',
      'Must be able to collaborate with wider groups on standards and best practices',
      'Must be able to work in an Agile operating model']}
    offerings={[
      'Competitive compensation',
      'Possibility to work remote when desired',
      'Work with the best professionals in the area',
      'Great work environment in our Cluj Napoca-Office',
      'Private healthcare insurance for employees'
    ]}
    imageElement={data}
  />
);

CareersDetails.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({})
};

CareersDetails.defaultProps = {
  data: null
};

export default CareersDetails;

export const query = graphql`
  query projectManagerCareerPage {
    file(relativePath: { eq: "careers-details-banner.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440)  {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
